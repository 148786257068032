/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */
import * as Sentry from '@sentry/remix'
import { RemixBrowser , useLocation, useMatches } from "@remix-run/react";
import { startTransition, StrictMode } from "react";
import { hydrateRoot } from "react-dom/client";
import React from 'react';

const env = import.meta.env.VITE_NODE_ENV

if (env == 'prod') {

  Sentry.init({
    dsn: 'https://dc7add1a41624b09a586997f95422101@glitchtip.fr8.in/24',
    tracesSampleRate: 0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    environment: env,
  
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useMatches,
      }),
      Sentry.replayIntegration(),
    ],
  })
  }

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
